<template>
  <v-card>
    <v-container fluid>
      <v-row>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-simple-table class="b-table-invoice">
            <template v-slot:default>
              <thead>
                <tr>
                  <th width="220">Descrição</th>
                  <th>Vidas</th>
                  <th>Valor</th>
                  <th width="50"></th>
                </tr>
              </thead>
              <tbody>
                <!-- Fatura base -->
                <tr>
                  <td>Fatura base</td>
                  <td class="b-table-invoice--value">
                    {{ model.amountBaseInvoiceLives }}
                  </td>
                  <td class="b-table-invoice--value">
                    {{ formatMoney(model.baseInvoiceValue) }}
                  </td>
                  <td>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          size="20"
                          color="primary"
                          @click="download()"
                        >
                          fa-solid fa-download
                        </v-icon>
                      </template>
                      <span>Baixar fatura base</span>
                    </v-tooltip>
                  </td>
                </tr>

                <!-- Coparticipação -->
                <tr v-if="model.hasCoparticipation">
                  <td colspan="2">Coparticipação</td>
                  <td>
                    <BaseMoney
                      dense
                      hide-details
                      :outlined="false"
                      v-model="model.copaymentValue"
                      v-if="model.situation === 'Fatura aberta'"
                    />

                    <span
                      class="b-table-invoice--value"
                      v-if="model.situation === 'Fatura fechada'"
                    >
                      {{ formatMoney(model.copaymentValue) }}
                    </span>
                  </td>
                  <td></td>
                </tr>

                <!-- Desconto coparticipação -->
                <tr v-if="model.hasCoparticipation">
                  <td colspan="2">Desconto coparticipação</td>
                  <td>
                    <BaseMoney
                      dense
                      hide-details
                      :outlined="false"
                      v-model="model.copaymentDiscountValue"
                      v-if="model.situation === 'Fatura aberta'"
                    />

                    <span
                      class="b-table-invoice--value"
                      v-if="model.situation === 'Fatura fechada'"
                    >
                      {{ formatMoney(model.copaymentDiscountValue) }}
                    </span>
                  </td>
                  <td></td>
                </tr>

                <!-- Bônus -->
                <tr>
                  <td colspan="2">Bônus</td>
                  <td>
                    <BaseMoney
                      dense
                      hide-details
                      :outlined="false"
                      v-model="model.bonusValue"
                      v-if="model.situation === 'Fatura aberta'"
                    />

                    <span
                      class="b-table-invoice--value"
                      v-if="model.situation === 'Fatura fechada'"
                    >
                      {{ formatMoney(model.bonusValue) }}
                    </span>
                  </td>
                  <td></td>
                </tr>

                <!-- Inclusões -->
                <tr>
                  <td>Inclusões</td>
                  <td class="b-table-invoice--value">
                    {{ model.amountInclusionLives }}
                  </td>
                  <td class="b-table-invoice--value">
                    {{ formatMoney(model.inclusionValue) }}
                  </td>
                  <td>
                    <v-icon
                      small
                      color="primary"
                      @click="expandInclusion = !expandInclusion"
                      v-if="model.amountInclusionLives > 0"
                    >
                      {{
                        expandInclusion
                          ? 'fa-solid fa-chevron-up'
                          : 'fa-solid fa-chevron-down'
                      }}
                    </v-icon>
                  </td>
                </tr>
                <template v-if="expandInclusion">
                  <tr
                    v-for="(inclusion, index) in model.inclusionLives"
                    :key="index"
                  >
                    <td class="font-12">
                      {{ inclusion.name }}
                      <v-tooltip bottom v-if="inclusion.readyForBilling">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" size="16" color="secondary">
                            fa-solid fa-circle-info
                          </v-icon>
                        </template>
                        <span>
                          Inclusão na base com cobrança no mês vigente
                        </span>
                      </v-tooltip>
                    </td>
                    <td></td>
                    <td class="b-table-invoice--value">
                      {{ formatMoney(inclusion.value) }}
                    </td>
                    <td></td>
                  </tr>
                </template>

                <!-- Alterações -->
                <tr>
                  <td>Alterações</td>
                  <td class="b-table-invoice--value">
                    {{ model.amountUpdateLives }}
                  </td>
                  <td class="b-table-invoice--value">
                    {{ formatMoney(model.updateValue) }}
                  </td>
                  <td>
                    <v-icon
                      small
                      color="primary"
                      @click="expandChange = !expandChange"
                      v-if="model.amountUpdateLives > 0"
                    >
                      {{
                        expandChange
                          ? 'fa-solid fa-chevron-up'
                          : 'fa-solid fa-chevron-down'
                      }}
                    </v-icon>
                  </td>
                </tr>
                <template v-if="expandChange">
                  <tr v-for="(update, index) in model.updateLives" :key="index">
                    <td class="font-12">{{ update.name }}</td>
                    <td></td>
                    <td class="b-table-invoice--value">
                      {{ formatMoney(update.value) }}
                    </td>
                    <td></td>
                  </tr>
                </template>

                <!-- Exclusões -->
                <tr>
                  <td>Exclusões</td>
                  <td class="b-table-invoice--value">
                    {{ model.amountExclusionLives }}
                  </td>
                  <td class="b-table-invoice--value">
                    {{ formatMoney(model.exclusionValue) }}
                  </td>
                  <td>
                    <v-icon
                      small
                      color="primary"
                      @click="expandExclusion = !expandExclusion"
                      v-if="model.amountExclusionLives > 0"
                    >
                      {{
                        expandExclusion
                          ? 'fa-solid fa-chevron-up'
                          : 'fa-solid fa-chevron-down'
                      }}
                    </v-icon>
                  </td>
                </tr>
                <template v-if="expandExclusion">
                  <tr
                    v-for="(exclusion, index) in model.exclusionLives"
                    :key="index"
                  >
                    <td class="font-12">
                      {{ exclusion.name }}
                      <v-tooltip bottom v-if="exclusion.readyForBilling">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" size="16" color="secondary">
                            fa-solid fa-circle-info
                          </v-icon>
                        </template>
                        <span>
                          Exclusão na base com cobrança no mês vigente
                        </span>
                      </v-tooltip>
                    </td>
                    <td></td>
                    <td class="b-table-invoice--value">
                      {{ formatMoney(exclusion.value) }}
                    </td>
                    <td></td>
                  </tr>
                </template>

                <!-- Acertos -->
                <tr>
                  <td colspan="2">Acertos</td>
                  <td>
                    <BaseAutonumeric
                      id="adjustmentValue"
                      name="adjustmentValue"
                      suffix="$"
                      hide-details
                      v-model="model.adjustmentValue"
                      v-if="model.situation === 'Fatura aberta'"
                    />

                    <span
                      class="b-table-invoice--value"
                      v-if="model.situation === 'Fatura fechada'"
                    >
                      {{ formatMoney(model.adjustmentValue) }}
                    </span>
                  </td>
                  <td></td>
                </tr>

                <!-- Fatura líquida -->
                <tr>
                  <td colspan="2" class="font-weight-bold">Fatura líquida</td>
                  <td class="b-table-invoice--value">
                    {{ formatMoney(model.netInvoiceValue) }}
                  </td>
                  <td></td>
                </tr>

                <!-- I.O.F -->
                <tr v-if="model.iofPercentage">
                  <td colspan="2">
                    <strong>I.O.F</strong>
                    ({{ formatPercentage(model.iofPercentage) }})
                  </td>
                  <td>
                    {{ formatMoney(model.iofValue) }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-row>
            <v-col cols="12">
              <CardInvoice
                icon="fa-solid fa-receipt"
                title="Fatura total"
                :total="model.totalInvoiceValue"
                align="row"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <CardInvoice
                icon="fa-solid fa-user"
                title="Titulares"
                :amount="model.amountHolders"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <CardInvoice
                icon="fa-solid fa-users"
                title="Dependentes"
                :amount="model.amountDependents"
              />
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12">
              <CardInvoice
                icon="fa-solid fa-heart-pulse"
                title="Total de vidas"
                :amount="model.amountLives"
              />
            </v-col>
            <v-col cols="12" class="invoice-buttons">
              <template v-if="hasButtonCalculate">
                <BaseButton
                  block
                  outlined
                  color="primary"
                  title="Calcular"
                  @click="calculate"
                  v-if="
                    checkPermission(['F1', 'F2', 'F3', 'CC1', 'CC2'], '!==')
                  "
                />
              </template>

              <template
                v-if="
                  !model.hasPreviousOpenInvoice &&
                  model.situation === 'Fatura aberta'
                "
              >
                <BaseButton
                  block
                  color="primary"
                  title="Fechar fatura"
                  type="submit"
                  :disabled="!valid"
                  @click="executeInvoice"
                  v-if="
                    checkPermission(['F1', 'F2', 'F3', 'CC1', 'CC2'], '!==')
                  "
                />
              </template>

              <template v-if="model.canReopenInvoice">
                <BaseButton
                  block
                  color="primary"
                  title="Reabrir fatura"
                  @click="reopen"
                  v-if="
                    checkPermission(['F1', 'F2', 'F3', 'CC1', 'CC2'], '!==')
                  "
                />
              </template>

              <v-alert
                outlined
                prominent
                color="warning"
                type="warning"
                border="left"
                v-if="model.hasPreviousOpenInvoice"
              >
                A <b>fatura</b> que você selecionou não pode ser fechada,
                verifique se há
                <b>faturas</b>
                anteriores em aberto.
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <DialogInvoiceCode
      v-if="dialog"
      :dialog="dialog"
      :invoiceId="model.id"
      :totalInvoiceValue="model.totalInvoiceValue"
      @closeModal="closeModal"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { checkPermission } from '@/helpers/permission';
import { formatMoney, formatPercentage } from '@/helpers/formatting';
import { confirmMessage, showMessage } from '@/helpers/messages';
import { downloadFile } from '@/helpers/download';
import { InvoiceService } from '@/services/api/invoice';

export default {
  components: {
    CardInvoice: () => import('@/components/cards/card-invoice'),
    DialogInvoiceCode: () => import('./dialog/dialog-invoice-code.vue')
  },

  props: {
    customerId: {
      type: String,
      require: true
    },
    contractId: {
      type: String,
      require: true
    },
    referenceDate: {
      type: String,
      require: true
    }
  },

  data: () => ({
    expandExclusion: false,
    expandInclusion: false,
    expandChange: false,
    valid: true,
    hasButtonCalculate: false,
    dialog: false
  }),

  computed: {
    ...mapGetters({
      model: 'invoice/getInvoice'
    })
  },

  methods: {
    formatMoney,
    formatPercentage,
    checkPermission,

    async executeInvoice() {
      if (this.model.hasAlreadyReopened) {
        const result = await confirmMessage(
          'Atenção!',
          `Deseja realmente fechar a fatura com o valor de <b>${formatMoney(
            this.model.totalInvoiceValue
          )}</b> ?`
        );

        if (result) {
          this.save();
        }
      } else {
        this.dialog = true;
      }
    },

    async save() {
      try {
        const invoiceService = new InvoiceService();
        const { status } = await invoiceService.closingInvoice({
          invoiceId: this.model.id,
          copaymentValue: this.model.copaymentValue,
          copaymentDiscountValue: this.model.copaymentDiscountValue,
          bonusValue: this.model.bonusValue,
          adjustmentValue: this.model.adjustmentValue,
          netInvoiceValue: this.model.netInvoiceValue,
          iofValue: this.model.iofValue,
          totalInvoiceValue: this.model.totalInvoiceValue,
          customerId: this.customerId,
          contractId: this.contractId,
          referenceDate: this.referenceDate
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');

          this.$emit('getInvoice', {
            customerId: this.customerId,
            contractId: this.contractId,
            referenceDate: this.referenceDate
          });

          this.hasButtonCalculate = false;

          this.$emit('reloadDates');
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async calculate() {
      try {
        const {
          copaymentValue,
          copaymentDiscountValue,
          bonusValue,
          adjustmentValue
        } = this.model;

        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.getInvoiceCalculation({
          copaymentValue,
          copaymentDiscountValue,
          bonusValue,
          adjustmentValue,
          invoiceId: this.model.id
        });

        if (status === 200) {
          this.$store.commit('invoice/setInvoice', data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async download() {
      try {
        const invoiceService = new InvoiceService();
        const { status, data } = await invoiceService.downloadBaseInvoice({
          invoiceId: this.model.id
        });

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async reopen() {
      const result = await confirmMessage(
        'Atenção!',
        `Deseja realmente reabrir a fatura ?`
      );

      if (result) {
        try {
          const invoiceService = new InvoiceService();
          const { status } = await invoiceService.reopenInvoice({
            invoiceId: this.model.id,
            customerId: this.customerId,
            contractId: this.contractId,
            referenceDate: this.referenceDate
          });

          if (status === 204) {
            showMessage('success', 'Fatura reaberta com sucesso!');

            this.$emit('getInvoice', {
              customerId: this.customerId,
              contractId: this.contractId,
              referenceDate: this.referenceDate
            });

            this.hasButtonCalculate = false;
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    closeModal(save) {
      this.dialog = false;

      if (save) {
        this.save();
      }
    }
  },

  watch: {
    model: {
      handler(newValue) {
        const {
          copaymentValue,
          copaymentDiscountValue,
          bonusValue,
          adjustmentValue,
          situation
        } = newValue;

        if (
          (copaymentValue ||
            copaymentDiscountValue ||
            bonusValue ||
            adjustmentValue) &&
          situation === 'Fatura aberta'
        ) {
          if (!this.hasButtonCalculate) {
            this.hasButtonCalculate = true;
          }
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
.biobe {
  .invoice-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .v-btn {
      margin: 0;

      + .v-btn {
        margin: 0;
      }
    }
  }

  .font-12 {
    font-size: 12px !important;
  }
}
</style>
